<style lang="scss" scoped>
    @media screen and (min-width: 769px) {
         .list-word {
    width: 100%;
    margin-top: 10px;
    display: grid !important;
    grid-template-columns: repeat(6, 1fr);
    flex-wrap: wrap;

    /* 在屏幕宽度小于某个阈值时，变为五列布局 */
    @media (max-width: 1669px) {
      grid-template-columns: repeat(5, 1fr);
    }

    /* 在屏幕宽度小于另一个阈值时，变为四列布局 */
      @media (max-width: 1506px) {
        grid-template-columns: repeat(4, 1fr);
      }

    //   @media (max-width: 1266px) {
    //     grid-template-columns: repeat(3, 1fr);
    //   }
  }
    }
</style>

<template>
    <div>
        <div>
            <ul class="list-word" v-if="data.listArr.length != 0">
                <cardBody :cardObject="item" v-for="(item, index) in data.listArr" :key="index"></cardBody>
            </ul>
        </div>
        <div>
            <a class="show-more" @click="showMore">{{ showmorecontent }}</a>
        </div>
    </div>
</template>

<script setup>
import cardBody from '@/components/cardBody'
import { ref, reactive, onMounted } from 'vue'
import request from '@/utils/request.js'
import { ElMessage, ElNotification } from 'element-plus'
import { page1Store } from '@/store/page1'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getToken } from '@/utils/auth'
import { phoneStore } from "../../store/phone1";
import bus from 'vue3-eventbus'
const store1 = phoneStore();
const store = useStore()
const router = useRouter()
const data = reactive({
    listArr: [],
})
onMounted(() => {
     store1.isSide = false;
    getLiveList()
})
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl
const page1StoreDa = page1Store()
const showmorecontent = ref('Show More')
const pageNum = ref(1)
const pageSize = ref(10)
//获取直播列表
async function getLiveList() {
    let params = {
        pageNum: pageNum.value,
        pageSize: pageSize.value
    }
    const { code, rows, total } = await request.get('/system/faConsultant/list?isHot=2', {
        params: params
    })
    if (code == 200 && pageNum.value <= Math.ceil(total / pageSize.value)) {
        console.log('添加')
        data.listArr = data.listArr.concat(rows)
    }
    if (pageNum.value >= Math.ceil(total / pageSize.value)) {
        showmorecontent.value = 'no more'
    }
}
// 展示更多
function showMore() {
    if (showmorecontent.value == 'no more') {
        // ElMessage('no more.')
        return
    }
    pageNum.value += 1
    getLiveList()
}
//未登录加入登录
const gotoLogin = () => {
    bus.emit('joinOnLogin', true)
}
// 点击进入详情页面
const goDetailPage = (id, item) => {
    router.push('/detailPage/' + id)
}
// 添加到收藏
async function changeFollow(item, index) {
    const userInfo = JSON.parse(store.state.userInfo)
    let data = {
        userId: userInfo.id,
        consultantId: item.id
    }
    const { code } = await request.post('/system/follower/add', data)
    // , duration: 0
    if (code == 200) { item.collect = '1'; ElNotification({ dangerouslyUseHTMLString: true, message: `<div>Added to favorites!</div>`, position: 'bottom-right' }) }
}
// 删除收藏
async function changeFollowDel(item, index) {
    const userInfo = JSON.parse(store.state.userInfo)
    let data = {
        userId: userInfo.id,
        consultantId: item.id
    }
    const { code } = await request.post('/system/follower/cancel', data)
    if (code == 200) { item.collect = '0'; ElNotification({ dangerouslyUseHTMLString: true, message: `<div>Removed from favorites!</div>`, position: 'bottom-right' }) }
}
function openShowMessage(item) {
    bus.emit('chatting', true)
    bus.emit('chattingObj', item.nickname)
}
</script>

<style lang="scss" scoped>
.filtergly {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray !important;
}

.show-more {
    color: #ffe400 !important;
    display: block;
    padding-top: 8px;
    margin: 0 auto;
    clear: both;
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    line-height: 10px;
    cursor: pointer;
    text-transform: capitalize;
    font-family:Poppins;
}

.show-more:before {
    right: 16px;
    margin-left: -50%;
}

.show-more:after {
    left: 16px;
    margin-right: -50%;
}

.show-more:before,
.show-more:after {
    content: '';
    position: relative;
    display: inline-block;
    width: 50%;
    height: 1px;
    vertical-align: middle;
    background-color: #ffffff2a;
}

.list-word {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    li {
        width: calc(20% - 10px);
        margin-right: 10px;
        list-style: none;
        margin-bottom: 16px;
        border-radius: 5px;
        overflow: auto;
        cursor: pointer;

        .word-img {
            position: relative;
            height: 146px;

            img {
                width: 100%;
                height: 100%;
                max-height: 146px;
                display: block;
                object-fit: cover;
            }
        }

        .word-icon {
            position: absolute;
            top: 10px;
            right: 8px;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
                display: block;
            }
        }

        .word-peple {
            position: absolute;
            color: #ffffff;
            background-color: #0fd6cc;
            top: 10px;
            left: 8px;
            font-size: 12px;
            padding: 3px 6px;
            border-radius: 6px;
        }

        .word-category {
            height: 70px;
            font-size: 12px;
            background: #ffffff;

            .word-message {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 4px;
                margin-right: 4px;

                .message-right {
                    margin: 5px;
                    display: flex;
                    align-items: center;
                    width: 50%;

                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 30px;
                        margin-right: 10px;
                    }

                    .message-name {
                        cursor: pointer;
                        font-size: 16px;
                        width: 80%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                .no-icon {
                    margin-right: 6px;
                    margin-top: 5px;
                    width: 37%;

                    p {
                        cursor: pointer;
                        text-align: center;
                        padding: 8px 2px;
                        background-color: #0477a5;
                        border-radius: 6px;
                        color: #ffffff;
                        font-size: 16px;
                    }
                }

                .message-icon {
                    display: flex;
                    margin-top: 3px;

                    p {
                        margin: 4px 0px;
                        background-color: rgba(162, 219, 236, 0.5);
                        border-radius: 18px;
                        padding: 4px 6px;
                        margin-right: 6px;
                    }

                    .icon1 {
                        font-size: 16px;
                    }
                }
            }

            .small-type {
                line-height: 20px;
                height: 20px;
                display: flex;
                flex-wrap: wrap;
                // overflow: hidden;
                overflow-y: hidden;
                border-radius: 10px;
                cursor: pointer;

                p {
                    background-color: rgba(162, 219, 236, 0.5);
                    margin-left: 7px;
                    display: block;
                    padding: 0px 6px;
                    border-radius: 8px;
                }
            }
        }
    }
}
</style>